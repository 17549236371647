<template>
  <div class="billing-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
              v-slot="{ handleSubmit, invalid, touched }"
              ref="updateBillingForm"
            >
              <form
                class="authentication-form"
                @submit.prevent="handleSubmit(updateBilling)"
              >
                <div class="row mb-4">
                  <div class="col-md-12 mb-3 mb-3">
                    <h4 class="h4">Billing Information</h4>
                  </div>
                  <div class="col-md-3">
                    <TextInput
                      v-model="settings.cost_per_sms"
                      type="number"
                      label="Cost Per SMS($)"
                      name="costPerSms"
                      rules="required"
                    />
                  </div>
                  <div class="col-md-3">
                    <TextInput
                      v-model="settings.cost_per_mms"
                      type="number"
                      label="Cost Per MMS($)"
                      name="costPerMms"
                      rules="required"
                    />
                  </div>
                  <div class="col-md-3">
                    <TextInput
                      v-model="settings.cost_per_call"
                      type="number"
                      label="Cost Per Minute($)"
                      name="costPerCall"
                      rules="required"
                    />
                  </div>
                  <div class="col-md-3">
                    <TextInput
                      v-model="settings.outbound_carrier_fee"
                      type="number"
                      label="Outbound Carrier Fee($)"
                      name="outboundCarrierFee"
                      rules="required"
                    />
                  </div>
                  <div class="col-md-3">
                    <TextInput
                      v-model="settings.cost_number_provision"
                      type="number"
                      label="Number Provisioning($)"
                      name="numberProvisioning"
                      rules="required"
                    />
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <h5 class="h5">Auto Recharge</h5>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="d-flex mb-3">
                          <b-form-checkbox
                            v-model="settings.auto_charge_enabled"
                            switch
                          ></b-form-checkbox>
                        </div>
                      </div>
                    </div>
                    <div v-if="settings.auto_charge_enabled" class="row">
                      <div class="col-md-4">
                        <h6 class="h6">Recharge Balance To</h6>
                        <p>Minimum amount is $20.00 and Maximum amount is $2000.00</p>
                        <TextInput
                          v-model="settings.recharge_to"
                          type="number"
                          name="RechargeTo"
                          rules="required"
                        />
                      </div>
                      <div class="col-md-4">
                        <h6 class="h6">When Balance Falls Below</h6>
                        <p>Minimum amount is $10.00 and Maximum amount is $1990.00</p>
                        <TextInput
                          v-model="settings.recharge_threshold"
                          type="number"
                          name="RechargeThreshold"
                          rules="required"
                        />
                      </div>
                      <div class="col-md-4">
                        <h6 class="h6">Total Recharge Amount</h6>
                        <p>Maximum amount that can be charged ( Daily )</p>
                        <TextInput
                          v-model="settings.max_recharge_daily"
                          type="number"
                          name="TotalRechargeAmount"
                          rules="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-4">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="d-flex mb-3">
                          <b-form-checkbox
                            v-model="updateExisitng"
                            switch
                            inline
                          >Update Existing Users</b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <b-form-group>
                  <b-button
                    variant="primary"
                    :disabled="loadingUpdate || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingUpdate" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data() {
    return {
      loadingUpdate: false,
      settings: {},
      updateExisitng: false,
    }
  },

  mounted() {
    this.user = Vue.util.extend({ }, this.$store.getters['auth/user'])
    this.settings = {
      ...this.user.settings
    }
  },

  methods: {

    updateBilling() {
      this.loadingUpdate = true

      const param = {
        settings: this.settings,
        updateExisitng: this.updateExisitng,
      }

      this.$store
        .dispatch('user/updateSetting', param)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch(() => {
          this.loadingUpdate = false
        })
    },
  }
}
</script>

